import {
  Box,
  Center,
  HStack,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard, OpaliteTextTitle } from "../shared/opalite_text";
import GreenCheckmarkSVG from "../../images/green_checkmark.svg";
import SmallCloseIconSVG from "../../images/small_close_icon.svg";
import {
  OpaliteButtonInput,
  OpaliteButtonStandard,
} from "../shared/opalite_buttons";
import BackgroundGradient from "../../images/background_amorphus_gradient.svg";
import BackgroundGradientDesktop from "../../images/background_amorphus_gradient_desktop_center.svg";
import { navigate } from "gatsby";
import {
  connectPhantomWallet,
  connectSolflareWallet,
} from "../../services/phantom";
import { ceil } from "lodash";
import HybridInputButton from "../shared/hybrid_input_button";

interface ConnectProps {}

const Connect: React.FC<ConnectProps> = (props) => {
  const [toggleMintingProgressDisplay, setToggleMintingProgressDisplay] =
    useState(true);
  const [price, setPrice] = useState("");

  return (
    <Box
      paddingTop="5rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100vh"
      backgroundRepeat="no-repeat"
      backgroundImage={{
        base: BackgroundGradient,
        sm: BackgroundGradientDesktop,
      }}
      backgroundSize="100%"
      backgroundPosition={{ base: "center", sm: "center" }}
      paddingInline="2rem"
    >
      <HStack
        justifyContent="space-between"
        borderRadius="5px"
        padding=".5rem"
        backgroundColor={COLORS_PRIMARY.MINTING_IN_PROCESS_BACKGROUND}
        width={{ base: "100%", md: "30%" }}
        display={toggleMintingProgressDisplay ? "flex" : "none"}
      >
        <HStack>
          <Image src={GreenCheckmarkSVG} />
          <OpaliteTextStandard color={COLORS_PRIMARY.BACKGROUND}>
            Minting in process
          </OpaliteTextStandard>
        </HStack>
        <Image
          onClick={() => setToggleMintingProgressDisplay(false)}
          src={SmallCloseIconSVG}
        />
      </HStack>
      <Box
        padding="2rem"
        width={{ base: "100%", md: "35%", xl: "25%" }}
        position="fixed"
        bottom="1rem"
        left="0"
      >
        <OpaliteTextTitle width="80%" fontSize="2rem" marginBottom="1rem">
          Connect to Solana wallet and set your price.
        </OpaliteTextTitle>
        <OpaliteTextStandard width="80%" marginBottom="1rem">
          This is where you'll keep your newly minted NFTs, along with the most
          important part... royalties.
        </OpaliteTextStandard>
        <Box
          display="flex"
          padding="1rem"
          justifyContent="center"
          width="100%"
          backgroundColor={COLORS_PRIMARY.BACKGROUND_OPAQUE}
          borderWidth="2px"
          borderRadius="10px"
          borderColor={COLORS_PRIMARY.FONT}
          flexDirection="column"
          marginBottom="1rem"
        >
          <OpaliteButtonStandard
            onClick={() => navigate("https://solflare.com/")}
            marginBottom="1rem"
            width="100%"
          >
            Connect Wallet
          </OpaliteButtonStandard>
          <OpaliteTextStandard fontSize=".75rem">
            Don't have a wallet? Setting one up is simple! Click{" "}
            <a
              style={{
                color: COLORS_PRIMARY.TEXT_LINK,
                textDecoration: "underline",
              }}
            >
              here
            </a>{" "}
            to get started!
          </OpaliteTextStandard>
        </Box>
        <HybridInputButton
          marginBottom="1rem"
          placeholder="$0.00"
          setterFunction={setPrice}
        >
          Confirm
        </HybridInputButton>
        <Slider marginBottom="1rem" aria-label="slider" defaultValue={30}>
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <OpaliteButtonStandard
          onClick={() => navigate("/phantom_connection")}
          width="100%"
        >
          Create your NFT Collection
        </OpaliteButtonStandard>
      </Box>
    </Box>
  );
};

export default Connect;
