import { Box, Center } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { OpaliteButtonInput } from "./opalite_buttons";
import { COLORS_PRIMARY } from "./opalite_colors";

interface HybridInputButtonProps {
  children: ReactNode;
  setterFunction: Function;
  icon?: any;
  placeholder?: string;
}

const HybridInputButton: React.FC<HybridInputButtonProps> = (props) => {
  return (
    <Center flexDirection="row" {...props}>
      <Input
        backgroundColor="#1b1b1b"
        width="75%"
        color={COLORS_PRIMARY.FONT}
        borderRadius="5px 0 0 5px"
        placeholder={props.placeholder}
        borderColor={COLORS_PRIMARY.FONT}
        borderWidth="2px"
        borderRightWidth="0"
        onChange={(e) => props.setterFunction(e.target.value)}
      ></Input>{" "}
      <OpaliteButtonInput iconType={props.icon}>
        {props.children}
      </OpaliteButtonInput>
    </Center>
  );
};

export default HybridInputButton;
