import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Connect from "../components/connect/connect";
import Header from "../components/header/header";

function ConnectPage() {
  return (
    <ChakraProvider>
      <Header />
      <Connect />
    </ChakraProvider>
  );
}

export default ConnectPage;
